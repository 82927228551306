import React from 'react';
import styled from '@emotion/styled';
import { H1, H2, H3, H4 } from './common/Heading';

const sidePadding = '5vw';
const breakPoint = '768px';

const Video = styled.div`
  background: #000;
  text-align: center;
  width: 100%;
  padding: ${sidePadding} 0;
  
  .video {
    width: 90%;
  }

  @media (min-width: ${breakPoint}) {
    margin: 0 ${sidePadding};
    width: calc(100% - ${sidePadding} - ${sidePadding});

    .video {
      width: 80vw;
      max-height: 70vh;
    }
  }
`;

const StickyTitle = styled.div`
  line-height: 1.2;
  padding: 70px ${sidePadding};
  text-align: left;
  
  h1 {
    margin-bottom: 30px;
  }

  @media (min-width: ${breakPoint}) {
    display: flex;
    padding: 160px ${sidePadding} 160px;
    position: sticky;
    top: 0;

    .empty {
      width: 25%;
      margin-right: 5%;
    }
    
    .title {
      width: 70%;
      margin-top: 0px;
    }

    h2 {
      max-width: 60%;
    }
  }
`;

const TeaserImage = styled.div`
  background: center center;
  background-size: cover;
  background-image: url(${props => props.src});
  width: 100%;
  height: 80vh;
  transform-origin: top center;
  
  @media (min-width: ${breakPoint}) {
    height: 100vh;
  }
`;

const Info = styled.div`
  line-height: 1.4;
  padding: calc(40px - 5%) ${sidePadding} 60px;
  background: #fff;

  .story {
    margin-top: 40px;
  }

  a, span {
    color: ${props => props.color}
  }

  @media (min-width: ${breakPoint}) {
    display: flex;
    padding: calc(160px - 5%) ${sidePadding} 160px;

    .roleNTech {
      width: 25%;
      margin-right: 5%;
    }
    
    .story {
      width: 70%;
      margin-top: 0px;
    }
  }
`;

const Details = styled.div`
  background: ${props => props.color};
  padding: 70px ${sidePadding} 40px;
  width: calc(100% - 40px);

  img {
    position: sticky;
    top: 70px;
  }

  .canvasWrapper {
    width: 100%;
    max-width: calc(1125px + 20%);
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

    > div {
      margin-right: ${sidePadding};

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: ${breakPoint}) {
    width: calc(100% - ${sidePadding} - ${sidePadding} - ${sidePadding} - ${sidePadding});
    margin: 0 ${sidePadding};
    padding: 150px ${sidePadding};

    img {
      top: 150px;
    }
  }
`;

class Project extends React.Component {
  constructor() {
    super();

    this.teaserImageRef = React.createRef();
  }


  componentDidMount() {
    window.addEventListener('scroll', (e) => {

      const element = this.teaserImageRef.current;
      const distanceFromTop = element.getBoundingClientRect().top;

      const y1 = 1;
      const y2 = 0.9;
      const x1 = window.innerHeight * 0.5;
      const x2 = window.innerHeight * 0.1;

      const x = distanceFromTop;

      let y = y1 + (x - x1) * (y2 - y1 ) / (x2 - x1)
          y = Math.min(y1, Math.max(y2, y));
        
      // console.log(y);

      element.style.transform = `scale(${y})`;
    })
  }

  render() {
    const { title, subTitle, color, teaserImageURL, teaserImageTitle, videoURL, role, tech, story, details, isLastItem } = this.props;
    return (
      <React.Fragment>
        <article>
          <Video>
            <video className="video" playsInline autoPlay loop muted poster={`${videoURL}.jpg`} id="background">
              <source src={`${videoURL}.mp4`} type="video/mp4" />
              <source src={`${videoURL}.webm`} type="video/webm" />
              <source src={`${videoURL}.ogv`} type="video/ogg" />
            </video>
          </Video>
          
          <div>
            <StickyTitle color={color}>
              <div className="empty"></div>
              <div className="title">
                <H1 className="title" style={{ marginLeft: '-5px'}}>{title}</H1>
                <H2
                  className="subTitle" 
                  dangerouslySetInnerHTML={{ __html: subTitle}}
                />
              </div>
            </StickyTitle>
            <div className="mobile-hide" style={{height: '25vh'}}>&nbsp;</div>
            {/* <img src={teaserImageURL} alt={teaserImageTitle}/> */}
          </div>
          <TeaserImage ref={this.teaserImageRef} onScroll={this.onScroll} src={teaserImageURL}>&nbsp;</TeaserImage>

          <Info color={color}>
            <div className="roleNTech">
              <H4>MY ROLE &amp; TECH</H4>
              <H2>{role}</H2>
              <H3>{tech}</H3>
            </div>
            <div className="story">
              <H4>STORY</H4>
              <H2 dangerouslySetInnerHTML={{ __html: story}} />
            </div>
          </Info>

          <Details color={color}>
            <div className="canvasWrapper">
              {details.map((d, i) => (
                <div key={`detail${i}`}>
                  <img width={d.width || 375} src={d.imageURL} alt={d.imageTitle}/>
                </div>
              ))}
            </div>
          </Details>
        </article>
        
        {!isLastItem &&
          // <h2 style={{
          //   padding: '20vh',
          //   textAlign: 'center',
          //   fontSize: '2em',
          //   color: '#000',
          //   fontWeight: '600'
          // }}>…</h2>
          <div style={{height: '50vh'}}>
            &nbsp;
          </div>
        }
      </React.Fragment>
    );
  }
}

export default Project;