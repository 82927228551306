import React from 'react';
import styled from '@emotion/styled';

const breakPoint = '768px';

const Heading1 = styled.h1`
  font-size: 2.6rem;
  letter-spacing: -0.05em;
  font-weight: 600;

  @media (min-width: ${breakPoint}) {
    font-size: 3.6rem;
  }
`;

const Heading2 = styled.h2`
  font-size: 1.8em;
  letter-spacing: -0.05em;
  margin-left: -4px;
  margin-bottom: 10px;
`;

const Heading3 = styled.h3`
  font-size: 1.2em;
  letter-spacing: -0.05em;
  font-weight: 100;
  margin-left: -1px;
`;

const Heading4 = styled.h4`
  font-size: 1em;
  color: #999;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const H1 = ({ children, ...props }) => (
  <Heading1 {...props}>{children}</Heading1>
);

const H2 = ({ children, ...props }) => (
  <Heading2 {...props}>{children}</Heading2>
);

const H3 = ({ children, ...props}) => (
  <Heading3 {...props}>{children}</Heading3>
);

const H4 = ({ children }) => (
  <Heading4>{children}</Heading4>
);

export {
  H1,
  H2,
  H3,
  H4
};