import React from 'react';
import styled from '@emotion/styled';
import { H2, H3, H4 } from './common/Heading';

const sidePadding = '5%';
const breakPoint = '768px';

const FooterWrapper = styled.article`
  line-height: 1.4;
  padding: 60px ${sidePadding} 40px;
  
  background: #fff;

  .me, .experience, .clients {
    margin-bottom: 60px;
  }

  @media (min-width: ${breakPoint}) {
    display: flex;
    width: calc(100% - ${sidePadding} - ${sidePadding});
    padding: 120px ${sidePadding} 120px;

    .me, .experience, .clients {
      margin-bottom: 0px;
    }

    .me {
      width: 30%;
      padding-right: 5%;
    }

    .experience {
      width: 40%;
      padding-right: 5%;
    }

    .clients {
      width: 30%;
    }
  }
`;

class Footer extends React.Component {
  render() {
    // const { title, subTitle, color, teaserImageURL, teaserImageTitle, videoURL, role, tech, story, details } = this.props;
    return (
      <FooterWrapper>
        <div className="me">
          <H4>ME</H4>
          <H3>
            Based in London. Engineer at Publicis Sapient. Javascript sculptor. Former teenager robot builder. Brazilian and zero-talented football player.
          </H3>
        </div>
        <ul className="experience">
          <li><H4>Today</H4></li>
          <li><H3>Senior Associate <br />Experience Technology Engineer @ Publicis Sapient</H3></li>
          <br/><br/>
          <li><H4>Recent past</H4></li>
          <li><H3>Lead Front End Engineer @ Flaner</H3></li>
          <li><H3>Senior Front End Engineer @ Denkwerk</H3></li>
          <li><H3>Web Engineer @ Huge</H3></li>
          <li><H3>Front End Developer @ MMDA</H3></li>
        </ul>

        <ul className="clients">
          <li><H4>Proudly worked for</H4></li>
          <li><H3>Nationwide Building Society, Santander Bank Group, Deutsche Telekom, Motel One, The Nielsen Company, Fiat Chrysler Automobiles, Banco Votorantim, Schneider Electric, Acquia, Samarco Mineração, Petrobras, Vale, Coca-Cola, The Body Shop, Shopping Iguatemi, Hospital Moinhos de Vento.</H3></li>
        </ul>
      </FooterWrapper>
    );
  }
}

export default Footer;