import React from 'react';
import Project from './components/Project';
import Footer from './components/Footer';
import data from './data.json';
import './App.css';

function App() {
  return (
    <div className="App">
      
      <header className="App-header Wrapper">
        <nav>
          <h1>
            <div className="mobile-hide">
              <a href="#Top">n<span>a</span>t<span>a</span>n</a>
            </div>

            <div className="mobile-show">
              <a href="#Top">N</a>
            </div>    
          </h1>
          {/* <h1<a href="#Top">N</a></h1> */}
          <ul>
            <li><a href="#AboutMe">AB<span>OU</span>T</a></li>
            <li><a href="mailto:natanielschling@gmail.com">M<span>AI</span>L<span> </span>M<span>E</span></a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/natanielschling/">L<span>I</span>NK<span>E</span>D<span>I</span>N</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/natanielschling">G<span>I</span>TH<span>U</span>B</a></li>
          </ul>
        </nav>
      </header>
      
      <a id="Top" />
      <div className="intro Wrapper">
        <div style={{display: 'flex', alignItems: 'flex-end', height: '85%'}}>
          Nataniel Schling
          <br />
          UX Engineer, London, UK
        </div>
      </div>
      
      {data.projects.map((p, i) => (
        <Project
          key={`project-${i}`}
          title={p.title}
          subTitle={p.subTitle}
          color={p.color}
          videoURL={p.videoURL}
          teaserImageURL={p.teaserImageURL}
          role={p.role}
          tech={p.tech}
          story={p.story}
          details={p.details}
          isLastItem={i === data.projects.length - 1}
        />
      ))}
      
      <a id="AboutMe" />
      <Footer />
      
    </div>
  );
}

export default App;
